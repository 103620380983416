<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'promocodes-index' }" />

    <template v-if="promocode">
      <h2 class="mb-4">Редактирование промокода</h2>

      <v-sheet class="pa-4" elevation="3">
        <promocode-form
          :promocode="promocode"
          :loading="updating"
          @change-field="changeField"
          @submit="updatePromocode"
        />
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Промокод с id "{{ id }}" не найден</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import { Promocode } from "~/libs/promocode/promocode";

import PromocodeForm from "~/components/promocodes/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      promocode: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchPromocode();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchPromocode() {
      this.loading = true;

      try {
        this.promocode = await this.$axios
          .$get(`discounts/promo-codes/${this.id}`)
          .then(promocode => Promocode.from(promocode));
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Промокод с id: "${this.id}" не найден ${html}`);
      } finally {
        this.loading = false;
      }
    },
    async updatePromocode() {
      if (this.updating) return;

      this.updating = true;

      try {
        this.promocode = await this.$axios
          .$put(`discounts/promo-codes/${this.id}`, Promocode.to(this.promocode))
          .then(promocode => Promocode.from(promocode));

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.promocode)).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    changeField(payload) {
      this.commitChange(this.promocode, payload);
    }
  },
  components: {
    PromocodeForm
  }
};
</script>
